

































  import {Component, Vue} from 'vue-property-decorator';

  let id = 0;
  @Component({
    name: 'Subject'
  })
  export default class Subject extends Vue {
    constructor(prop) {
      super(prop);
    };

    private options: any[] = [{}, {}, {}];
    private subjectArr: any[] = [];
    private COURSE_TYPE_CHECKED = false;
    private GRADE_CHECKED = false;
    private TEACH_BOOK_VERSION_CHECKED = false;
    private SEMESTER_CHECKED = false;
    private COURSE_TYPE = [];
    private GRADE = [];
    private TEACH_BOOK_VERSION = [];
    private SEMESTER = [];
    private cellArr = [
      {
        type: 'GRADE',
	      title: '年级',
	      checked: false,
	      bgColor: '#D3F8F6',
	      color: '#A6E3E0',
	      data: []
      },
      {
        type: 'SEMESTER',
        title: '学期管理',
        checked: false,
        bgColor: '#E8EAFC',
        color: '#CDD1FF',
        data: []
      },
	    {
        type: 'TEACH_BOOK_VERSION',
		    title: '教材版本',
		    checked: false,
		    bgColor: '#FFF6E3',
        color: '#FDE9C0',
		    data: []
	    },
	    {
        type: 'COURSE_TYPE',
        title: '班型',
        checked: false,
        bgColor: '#FFECE4',
        color: '#FFD4C3',
        data: []
      }]


    private async getSubject() {
      const res: any = await this.axios.post('/system/dictionary/querySubject');
      if (res.result) {
        Object.keys(res.json).forEach((key, index) => {
          let subjectParName = ''
          switch (key) {
            case 'high':
              subjectParName = '高中'
              break;
            case 'middle':
              subjectParName = '初中'
              break;
            case 'primary':
              subjectParName = '小学'
              break;
            default:
              subjectParName = '小学'
              break;
          }
          this.$set(this.options[index], 'value', key)
          this.$set(this.options[index], 'label', subjectParName)
          this.$set(this.options[index], 'children', res.json[key])
        });
        this.options.forEach((item: any, index: number) => {
          item.children.forEach((key: any) => {
            this.$set(key, 'value', key.code)
            this.$set(key, 'label', key.name)
          });
        });
        this.options = this.options.filter(i => i.children && i.children.length !== 0)
        this.subjectArr[0] = this.options[0].value;
        this.subjectArr[1] = this.options[0].children[0].value;
      }
    };

    private getSystem() {
      this.axios.post('/admin/courseSystem/queryCourseSystemBySubject', {subjectId: this.subjectArr[1]}).then((res: any) => {
        Object.keys(res.json).forEach((item: any) => {
          this.cellArr.forEach((val: any) => {
            if (item === val.type) {
              val.data = res.json[item];
              val.checked = res.json[item].every(item => item.check == true);
            }
          })
        })
        // if (res.result) {
        //   this.GRADE = res.json.GRADE;
        //   this.GRADE_CHECKED = res.json.GRADE.every(item => item.isCheck === 1);
        //   this.SEMESTER = res.json.SEMESTER;
        //   this.SEMESTER_CHECKED = res.json.SEMESTER.every(item => item.isCheck === 1);
        //   this.TEACH_BOOK_VERSION = res.json.TEACH_BOOK_VERSION;
        //   this.TEACH_BOOK_VERSION_CHECKED = res.json.TEACH_BOOK_VERSION.every(item => item.isCheck === 1);
        //   this.COURSE_TYPE = res.json.COURSE_TYPE;
        //   this.COURSE_TYPE_CHECKED = res.json.COURSE_TYPE.every(item => item.isCheck === 1);
        // };
      })
    }

    private async initData() {
      await this.getSubject();
      this.getSystem();
    };

    private async change(item) {
      const cascaderREF: any = this.$refs['cascader'];
      let subjectName = cascaderREF.presentText.split('/')[1];
      let relationValue = item.typeCode == 'TEACH_BOOK_VERSION' ? item.id : item.id
      const res: any = await this.axios.post('/admin/courseSystem/saveOrUpdateCourseSystemBySubjectId', {
        subjectId: this.subjectArr[1],
        subjectName,
        relationName: item.name,
        relationValue,
        relationType: item.typeCode
      }, {headers: {'Content-Type': 'application/json'}});
      if (res.result) {
        this.getSystem();
      }
    }

    private async changeAll(type, checked, data) {
      let dictionaries;
      if (checked) {
        data.forEach(item => {
          item.isCheck = true
        });
        dictionaries = data.map(item => ({id: (type === 'TEACH_BOOK_VERSION' ? item.id : item.id), name: item.name}));
      } else {
        data.forEach(item => {
          item.isCheck = false
        });
        dictionaries = [];
      }
      const cascaderREF: any = this.$refs['cascader'];
      let subjectName = cascaderREF.presentText.split('/')[1];
      const res: any = await this.axios.post('/admin/courseSystem/batchSaveOrUpdateCourseSystemBySubjectId', {
        subjectId: this.subjectArr[1],
        subjectName,
        relationType: type,
        dictionaries
      }, {headers: {'Content-Type': 'application/json'}});
      this.getSystem();
    };

    private subjectChange() {
      this.getSystem();
    }

    public created() {
      this.initData();
    };

    public mounted() {
      let dom: any = document.getElementsByClassName('container')[0];
      dom.style.height = '100%'
    };

    public destroyed() {
      let dom: any = document.getElementsByClassName('container')[0];
      dom.style.height = ''
    }
  }
